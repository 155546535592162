<template>
  <div>
    <div class="main">
      <p class="title">机构认证</p>
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="机构名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入机构名称"></el-input>
        </el-form-item>
        <el-form-item label="代表作" prop="works">
          <el-input
            v-model="form.works"
            placeholder="请填写机构的代表作歌名，附上试听链接更好"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="contacts">
          <el-input
            v-model="form.contacts"
            placeholder="请填写机构入驻联系人"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系方式" prop="contactsPhone">
          <el-input
             class="el-input1"
            readonly="readonly"
            v-model="form.contactsPhone"
            placeholder="请填写联系机构的手机号，以便于我们取得联系"
          >
            <div class="leftphone" slot="prepend">+{{zone}}</div>
          </el-input>
        </el-form-item>
        <el-form-item label="联系人微信">
          <el-input
              v-model="form.weChatNumber"
              placeholder="请填写您的微信号"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系人QQ">
          <el-input
              v-model="form.qqNumber"
              placeholder="请填写您的QQ号"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-row type="flex" justify="center">
            <el-col :span="16">
              <button class="btn" @click="btn">返 回</button>
              <el-button :loading="submitbol" @click="submitForm">提交申请</el-button>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {usermsg} from '../api/index';
export default {
  created () {
    usermsg().then(res=>{
      if(res.code === 0){
        this.form.contactsPhone = res.data.mobile;
        this.zone = res.data.zone;
      }
    })
  },
  data () {
    let visiblePhone = (rule, value, checked) => {
      let phone = /^1[3456789]d{9}$/
      if (value.length == 0 && !phone.test(value)) {
        checked(new Error('请输入正确的手机号'))
      } else if (this.$store.state.user.mobile != value) {
        checked(new Error('请与此账号绑定的手机号一致'))
      } else {
        checked()
      }
    }
    return {
      form: {
        name: '',
        works: '',
        contacts: '',
        contactsPhone: '',
        qqNumber:"",
        weChatNumber:'',
      },
      zone:'',
      rules: {
        name: [{ required: true, message: '请输入机构名称', trigger: 'blur' }],
        works: [{ required: true, message: '请输入代表作', trigger: 'blur' }],
        contacts: [
          { required: true, message: '请填写联系人姓名', trigger: 'blur' }
        ],
        contactsPhone: [
          { required: true, message: '请输入联系方式', trigger: 'blur' },
          { validator: visiblePhone, trigger: 'blur' }
        ]
      },
        submitbol:false,//节流
    }
  },
  methods: {
    submitForm () {
      this.$refs['form'].validate((valid) => {
        if (valid) {
            this.submitbol=true;
          this.$post('/api/company/submit', {
            // 机构名称
            companyName: this.form.name,
            // 联系人姓名
            contractPerson: this.form.contacts,
            // 代表作
            masterpiece: this.form.works,
            // 电话
            phone: this.form.contactsPhone,
          //  qq
            qqNumber:this.form.qqNumber,
            //微信
            weChatNumber: this.form.weChatNumber
          }).then((res) => {
            if (res.code == 0) {
                  this.submitbol=false;
              // if(localStorage.release==1 ){
              this.$post('/api/user/userMsg').then(res => {
                  this.$store.commit('UMSG',res.data)
                if (localStorage.release == 1||(res.data.webLoginType==1&&res.data.userCompanyVo.status==2)) {
                  this.$post('/api/company/identityChange', {
                    id: res.data.userCompanyVo.id,
                    type: 1
                  }).then((res) => {
                    let data = res.data
                    if (res.code == 0) {
                      this.$store.commit('LOGIN', {
                        secret: data.secret,
                        session: data.session,
                        user: data.user
                      })
                    }
                  })
                }
              })
              this.$store.dispatch('getMechanismList')
              this.$router.push({
                path: '/certification/examine',
                query: { flag: true }
              })


              this.$refs['form'].resetFields()

            }
          })
        } else {
          return false
        }
      })
    },
    btn () {
      this.$router.replace("/")
    }
  }
}
</script>

<style scoped lang="scss">
.main {
  overflow: hidden;
  margin: 0px auto;
  margin-top: 69px;
  width: 1140px;
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 0px 200px;
  .title {
    margin-top: 50px;
    margin-bottom: 70px;
    height: 56px;
    font-size: 40px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 56px;
    letter-spacing: 1px;
  }
  .el-button {
    width: 202px;
    height: 50px;
    background: #ff004d;
    opacity: 0.5;
    color: #fff;
    margin-top: 76px;
  }
  .btn {
    margin-right: 40px;
    color:red;
    width: 202px;
    height: 50px;
    background: #efefef;
    border: none;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    text-align: CENTER;
    color: #ff004d;
  }
  .el-button:hover {
    opacity: 1;
  }
}
.copyright {
  margin-top: 32px;
  background-color: black;
}
  ::v-deep .el-input1{
      .el-input__inner{
        border-bottom: 1px solid #ececec;
      }
      .el-input-group__prepend{
        border: none;
        border-bottom: 1px solid #ececec;
        background-color:#ffffff;
}
  }
  ::v-deep .el-input{
    .el-input__inner{
      border: none;
      border-bottom: 1px solid #ececec;
      background-color:#ffffff;
    }
  }

</style>
